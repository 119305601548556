import React, { useState } from 'react';
import 'nes.css/css/nes.min.css';

const ComingSoonPage = () => {
  const [likeCount, setLikeCount] = useState(0);
  const [selectedOctocat, setSelectedOctocat] = useState('nes-octocat animate');

  const incrementLike = () => setLikeCount(prev => prev + 1);

  const changeOctocat = () => {
    const octocats = ['nes-octocat animate', 'nes-mario animate', 'nes-ash animate', 'nes-pokeball animate'];
    const currentIndex = octocats.indexOf(selectedOctocat);
    const nextIndex = (currentIndex + 1) % octocats.length;
    setSelectedOctocat(octocats[nextIndex]);
  };

  return (
    <div className="nes-container is-dark with-title" style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#000',
      color: '#fff',
      fontFamily: '"Press Start 2P", cursive',
    }}>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
          body { overflow: hidden; }
        `}
      </style>
      <h1 className="title" style={{ fontSize: '2.5rem', marginBottom: '2rem' }}>Emerplex</h1>
      <div className="nes-container is-rounded" style={{ backgroundColor: '#212529', padding: '2rem', marginBottom: '2rem', maxWidth: '600px', width: '90%' }}>
        <h2 className="nes-text is-primary" style={{ fontSize: '2rem', marginBottom: '1rem' }}>Coming Soon</h2>
        <p className="nes-text is-success" style={{ fontSize: '1rem', marginBottom: '2rem' }}>Get ready for an epic adventure!</p>
        <div className="nes-balloon from-left" style={{ marginBottom: '2rem' }}>
          <p>Check out our awesome collections while you wait!</p>
        </div>
        <progress className="nes-progress is-pattern" value="75" max="100" style={{ marginBottom: '1rem' }}></progress>
        <p>Development Progress: 75%</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', flexWrap: 'wrap', marginBottom: '2rem' }}>
        <a href="https://www.tensor.trade/trade/beefellaz" target="_blank" rel="noopener noreferrer" className="nes-btn is-primary">Beefellaz</a>
        <a href="https://www.tensor.trade/trade/fakeborts" target="_blank" rel="noopener noreferrer" className="nes-btn is-success">Fakeborts</a>
        <a href="https://www.tensor.trade/trade/emerplex" target="_blank" rel="noopener noreferrer" className="nes-btn is-warning">Emerplex</a>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '2rem' }}>
        <button onClick={incrementLike} className="nes-btn is-error">
          <i className="nes-icon heart"></i> Like
        </button>
        <span className="nes-text is-disabled">Likes: {likeCount}</span>
      </div>
      <div className={selectedOctocat} onClick={changeOctocat} style={{ marginBottom: '1rem', cursor: 'pointer' }}></div>
      <p className="nes-text is-disabled">Click the sprite to change it!</p>
    </div>
  );
};

export default ComingSoonPage;