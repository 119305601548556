import React from 'react';
import ComingSoonPage from './ComingSoonPage';
import 'nes.css/css/nes.min.css';

function App() {
  return (
    <div className="App">
      <ComingSoonPage />
    </div>
  );
}

export default App;